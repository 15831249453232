import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import Loader from './Loader';

// Модальные окна
import ErrorModal from './ErrorModal';
import MultitapModal from './modals/MultitapModal';
import EnergyLimitModal from './modals/EnergyLimitModal';
import FullEnergyModal from './modals/FullEnergyModal';
import SuccessModal from './modals/SuccessModal';

import skyBg from '../assets/img/sky-bg.png';
import coinIcon from '../assets/img/icons/coin.svg';
import tapIcon from '../assets/img/icons/tap.png';
import energyIcon from '../assets/img/icons/boost.png';

// Импорт CSS файлов
import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

function Boosts({ user, token, refreshUserData }) {
  const [multitapLevel, setMultitapLevel] = useState(1);
  const [energyLimitLevel, setEnergyLimitLevel] = useState(1);
  const [multitapCost, setMultitapCost] = useState(0);
  const [energyLimitCost, setEnergyLimitCost] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMultitapModalVisible, setMultitapModalVisible] = useState(false);
  const [isEnergyLimitModalVisible, setEnergyLimitModalVisible] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isRestoreAvailable, setRestoreAvailable] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isRestoreModalVisible, setRestoreModalVisible] = useState(false);
  const [currentEnergy, setCurrentEnergy] = useState(0); // текущее количество энергии
  const [energyLimit, setEnergyLimit] = useState(2000); // лимит энергии
  const [progressBarWidth, setProgressBarWidth] = useState(0); // ширина прогресс-бара

  // Отключим верхний таймер (он временно не используется)
  useEffect(() => {
    if (user && user.user_id && token) {
      fetchBoostData(user.user_id, token);
    } else {
      setIsLoading(false);
    }
  }, [user, token]);

  // Таймер для восстановления энергии
  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(prevTime => prevTime - 1000); // уменьшаем время на 1 секунду
      } else {
        setRestoreAvailable(true);
      }
    }, 1000);

    return () => clearInterval(timer); // очищаем таймер при размонтировании
  }, [timeLeft]);

  const fetchBoostData = async (userId, token) => {
    try {
      const [userRes, multitapRes, energyLimitRes] = await Promise.all([
        axios.get(`/api/user/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } }),
        axios.get(`/api/multitap/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } }),
        axios.get(`/api/energy_limit/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } })
      ]);

      const userData = userRes.data;
      const currentMultitapLevel = userData.click_value || 1;
      const currentEnergyLimitLevel = userData.energy_tap || 1;

      setCurrentEnergy(userData.energy); // обновляем текущее количество энергии
      setEnergyLimit(userData.energy_limit); // устанавливаем лимит энергии

      setMultitapLevel(currentMultitapLevel);
      setEnergyLimitLevel(currentEnergyLimitLevel);
      setMultitapCost(multitapRes.data.cost || 0);
      setEnergyLimitCost(energyLimitRes.data.cost || 0);

      updateProgressBar(userData.energy, userData.energy_limit); // обновляем прогресс-бар
      calculateRestoreTime(userData.last_energy_update); // рассчитываем время до восстановления
      setIsLoading(false);
    } catch (err) {
      setError('Ошибка при загрузке данных бустов.');
      setIsLoading(false);
    }
  };

  const updateProgressBar = (current, limit) => {
    const percentage = (current / limit) * 100;
    setProgressBarWidth(percentage);
  };

  const calculateRestoreTime = (lastEnergyUpdate) => {
    const lastUpdate = new Date(lastEnergyUpdate);
    const now = new Date();
    const hoursDiff = (now - lastUpdate) / 36e5;
    if (hoursDiff < 6) {
      setRestoreAvailable(false);
      const remainingTime = (6 - hoursDiff) * 3600 * 1000;
      setTimeLeft(remainingTime);
    } else {
      setRestoreAvailable(true);
    }
  };

  const handlePurchase = async (type) => {
    try {
      const response = await axios.post(`/api/purchase_${type}`, { user_id: user.user_id }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data.success) {
        fetchBoostData(user.user_id, token);
        setSuccessMessage(type === 'multitap' ? '+1 монет за тап' : '+500 энергии');
        closeModal();
        // Обновляем данные на главной странице после успешной покупки
        if (refreshUserData) {
          refreshUserData(); // Вызов функции обновления данных пользователя
        }
      } else {
        alert(response.data.message || 'Покупка не удалась');
      }
    } catch (err) {
      console.error('Ошибка при покупке:', err);
      setError('Ошибка при покупке. Пожалуйста, попробуйте позже.');
    }
  };

  const confirmPurchase = async (type) => {
    try {
      const response = await axios.post(`/api/purchase_${type}`, { user_id: user.user_id }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data.success) {
        fetchBoostData(user.user_id, token);
        setSuccessMessage(type === 'multitap' ? '+1 монет за тап' : '+500 энергии');
        closeModal();
        refreshUserData(); // обновляем данные пользователя
      } else {
        alert(response.data.message || 'Покупка не удалась');
      }
    } catch (err) {
      setError('Ошибка при покупке.');
    }
  };

  const restoreEnergy = () => {
    axios.post('/api/restore_energy', {}, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      if (response.data.success) {
        setCurrentEnergy(energyLimit); // восстанавливаем энергию до лимита
        updateProgressBar(energyLimit, energyLimit); // обновляем прогресс-бар
        setSuccessMessage('Энергия успешно восстановлена');
        setSuccessModalVisible(true);
        setTimeLeft(6 * 3600 * 1000); // обновляем таймер
        setRestoreAvailable(false);
      } else {
        alert(response.data.message);
      }
    }).catch(err => {
      console.error('Ошибка восстановления энергии:', err);
    });
  };

  const closeModal = () => {
    setMultitapModalVisible(false);
    setEnergyLimitModalVisible(false);
    setSuccessModalVisible(false);
  };

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes}:${seconds}`;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <main className="main">
      <section className="screen">
        <div className="screen-bg">
          <img src={skyBg} alt="bg" className="screen-bg__img" />
        </div>
        <div className="screen-content scroll">
          <div className="wrapper">
            <div className="boost">
              <div className="home-bottom__boost">
                <p className="home-bottom__boost-number">
                  <img src={energyIcon} alt="boost" className="home-bottom__boost-icon" />
                  <span>{currentEnergy}/{energyLimit}</span>
                </p>
                <div className="home-bottom__boost-bar">
                  <span className="home-bottom__boost-progress" style={{ width: `${progressBarWidth}%` }}></span>
                </div>
              </div>
              {/* Таймер восстановления только для восстановления энергии */}
              <p class="boost-cooldown">Энергия восстановится через 05:55:32</p>
              <div className="tasks-list">
                {/* Блок для покупки энергии */}
                <div className="tasks-list__item"  style={{ cursor: 'pointer' }}>
                  <div className="tasks-list__item-place">
                    <img src={energyIcon} style={{ maxHeight: '26px' }} alt="icon" className="tasks-list__item-icon" />
                  </div>
                  <div className="tasks-list__item-column">
                    <p className="tasks-list__item-name" onClick={() => setRestoreModalVisible(true)}>Купить энергию</p>
                    <p className="tasks-list__item-bonus">
                      <img src={coinIcon} alt="coin" className="tasks-list__item-coin" />
                      {energyLimitCost} монет
                    </p>
                  </div>
                </div>
                {/* Блок для покупки тапов */}
                <div className="tasks-list__item"  style={{ cursor: 'pointer' }}>
                  <div className="tasks-list__item-place">
                    <img src={tapIcon} style={{ maxHeight: '26px' }} alt="icon" className="tasks-list__item-icon" />
                  </div>
                  <div className="tasks-list__item-column" onClick={() => setMultitapModalVisible(true)}>
                    <p className="tasks-list__item-name">Купить тап</p>
                    <p className="tasks-list__item-bonus">
                      <img src={coinIcon} alt="coin" className="tasks-list__item-coin" />
                      {multitapCost} монет
                    </p>
                  </div>
                </div>
              </div>
              <button className="button boost-button" onClick={restoreEnergy}>
                Восстановить энергию <br /><span>Доступ через {formatTimeLeft(timeLeft)}</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <BottomNav user={user} onNavClick={() => {}} />
        
  {/* Модальные окна */}
      <MultitapModal
        isVisible={isMultitapModalVisible}
        onClose={() => setMultitapModalVisible(false)}
        onConfirm={() => handlePurchase('multitap')}
        cost={multitapCost}
      />
      <EnergyLimitModal
        isVisible={isEnergyLimitModalVisible}
        onClose={() => setEnergyLimitModalVisible(false)}
        onConfirm={() => handlePurchase('energy_limit')}
        cost={energyLimitCost}
      />
      <FullEnergyModal
        isVisible={isRestoreModalVisible}
        onClose={() => setRestoreModalVisible(false)}
        onConfirm={restoreEnergy}
      />
      <SuccessModal
        isVisible={isSuccessModalVisible}
        message={successMessage}
        onClose={() => setSuccessModalVisible(false)}
      />
    </main>
  );
}

export default Boosts;
