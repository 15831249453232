import React from 'react';

function MultitapModal({ isVisible, onClose, onConfirm, cost }) {
  if (!isVisible) return null;

  return (
    <div className="modal autotap" onclick="void(0)">
        <div className="modal-content">
            <div className="modal-content__wrapper">
                <p className="modal-content__title">Увеличивает количество монет, которое вы можете заработать за одно нажатие.</p>
                <p className="modal-content__title">+1 монет за тап для следующего уровня.</p>
                <p className="modal-content__title">Купить автотап за {cost} монет?</p>
                <img src="assets/img/icons/close-popup.svg" alt="close" className="modal-content__close modal-close"/>
                <div className="modal-actions">
                  <button  className="button" onClick={onClose}>Отмена</button>
                  <button  className="button" onClick={onConfirm}>Подтвердить</button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default MultitapModal;
