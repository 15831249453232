import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import ErrorModal from './ErrorModal';

// Импорт CSS файлов
import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

// Импорт изображений
import skyBg from '../assets/img/sky-bg.png';

function Leaderboard({ user }) {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Состояние для управления видимостью контейнера

  useEffect(() => {
    axios.get('/api/leaderboard')
      .then(res => {
        setLeaderboardData(res.data);
        setIsLoading(false);
        setTimeout(() => {
          setIsVisible(true); // Показать контейнер с анимацией
        }, 100); // Небольшая задержка перед анимацией
      })
      .catch(err => {
        setError('Ошибка при загрузке данных рейтинга.');
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <main className="main">
      <section className="screen">
        <div className="screen-bg">
          <img src={skyBg} alt="bg" className="screen-bg__img" />
        </div>
        <div className="screen-content scroll">
          <div className="wrapper">
            <div className="rating">
              <p className="rating-title">Рейтинг</p>
              <div className="rating-list">
                <div className="rating-list__item header">
                  <p className="rating-list__item-text">Номер</p>
                  <p className="rating-list__item-text">Имя</p>
                  <p className="rating-list__item-text">Уровень</p>
                  <p className="rating-list__item-text">Рефералов</p>
                </div>
                <div className="rating-list__group">
                  {isLoading ? (
                    <p>Загрузка...</p>
                  ) : (
                    leaderboardData.map((user, index) => (
                      <div key={user.user_id} className="rating-list__item">
                        <p className="rating-list__item-text">{String(index + 1).padStart(2, '0')}</p>
                        <p className="rating-list__item-text">{user.username || 'Аноним'}</p>
                        <p className="rating-list__item-text">{user.level} lvl</p>
                        <p className="rating-list__item-text">{user.count_referal}</p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomNav user={user} onNavClick={() => {}} />
    </main>
  );
}

export default Leaderboard;
