import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import img1 from '../assets/img/combo/img-1.png';
import img2 from '../assets/img/combo/img-2.png';
import img3 from '../assets/img/combo/img-3.png';
import img4 from '../assets/img/combo/img-4.png';
import img5 from '../assets/img/combo/img-5.png';
import img6 from '../assets/img/combo/img-6.png';
import img7 from '../assets/img/combo/img-7.png';
import img8 from '../assets/img/combo/img-8.png';
import img9 from '../assets/img/combo/img-9.png';
import img10 from '../assets/img/combo/img-10.png';
import img11 from '../assets/img/combo/img-11.png';
import img12 from '../assets/img/combo/img-12.png';
import questionIcon from '../assets/img/combo/question.svg';
import crossIcon from '../assets/img/combo/cross.svg';
import skyBg from '../assets/img/sky-bg.png'; // Импорт фона
import Modal from './modals/ResultModal'; // Модальное окно для результата

// Импорт CSS файлов
import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

const images = [
  { id: 1, src: img1 },
  { id: 2, src: img2 },
  { id: 3, src: img3 },
  { id: 4, src: img4 },
  { id: 5, src: img5 },
  { id: 6, src: img6 },
  { id: 7, src: img7 },
  { id: 8, src: img8 },
  { id: 9, src: img9 },
  { id: 10, src: img10 },
  { id: 11, src: img11 },
  { id: 12, src: img12 },
];

function Combo({ user }) {
  const [selectedImages, setSelectedImages] = useState([null, null, null, null]);
  const [selectedImageForMobile, setSelectedImageForMobile] = useState(null); // Для выбора на телефоне
  const [correctIndexes, setCorrectIndexes] = useState([]); // Массив для индексации угаданных карточек
  const [modalData, setModalData] = useState(null); // Данные для модального окна
  const [isInteractionLocked, setIsInteractionLocked] = useState(false); // Для блокировки на 24 часа

  useEffect(() => {
    const fetchLastAttempt = async () => {
      try {
        const response = await axios.get('/api/get_last_attempt', {
          params: { user_id: user.user_id },
        });

        const lastAttempt = response.data;
        if (lastAttempt) {
          const timePassed = Date.now() - new Date(lastAttempt.attempted_at).getTime();
          if (timePassed < 24 * 60 * 60 * 1000) {
            setSelectedImages(JSON.parse(lastAttempt.attempted_cards)); // Восстанавливаем выбранные карточки
            setCorrectIndexes(lastAttempt.correct_indexes || []); // Восстанавливаем правильные индексы
            setIsInteractionLocked(true); // Блокируем взаимодействие на 24 часа
          }
        }
      } catch (error) {
        console.error('Ошибка при получении последней попытки:', error);
      }
    };

    fetchLastAttempt();
  }, [user.user_id]);

  // Обработчик перетаскивания для добавления изображения в слот
  const handleDrop = (index, image) => {
    if (!selectedImages.some((selectedImage) => selectedImage && selectedImage.id === image.id)) {
      const newSelectedImages = [...selectedImages];
      newSelectedImages[index] = image;
      setSelectedImages(newSelectedImages);
    }
  };

  // Обработчик удаления изображения из слота
  const handleRemove = (index) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages[index] = null;
    setSelectedImages(newSelectedImages);
  };

  // Функция для начала перетаскивания на ПК
  const handleDragStart = (event, image) => {
    event.dataTransfer.setData('image', JSON.stringify(image));
  };

  // Обработка перетаскивания и сброса изображения в слот
  const handleDropSlot = (index, event) => {
    event.preventDefault();
    const droppedImage = JSON.parse(event.dataTransfer.getData('image'));
    handleDrop(index, droppedImage);
  };

  // Функция для выбора на мобильном
  const handleImageSelectMobile = (image) => {
    setSelectedImageForMobile(image); // Запоминаем выбранное изображение
  };

  // Функция для добавления изображения в слот при клике на мобильном
  const handleSlotClickMobile = (index) => {
    if (selectedImageForMobile && !selectedImages.some((selectedImage) => selectedImage && selectedImage.id === selectedImageForMobile.id)) {
      handleDrop(index, selectedImageForMobile);
      setSelectedImageForMobile(null); // Сбрасываем выбор
    }
  };

  // Проверка, используется ли изображение в верхних слотах
  const isImageUsed = (image) => selectedImages.some((selectedImage) => selectedImage && selectedImage.id === image.id);

  // Обработчик кнопки "Проверить"
  const handleCheckCombo = () => {
    if (isInteractionLocked) return; // Если заблокировано, ничего не делаем

    axios.post('/api/check_combo', { user_id: user.user_id, selectedImages })
      .then((response) => {
        const { correctIndexes, correctCount, coinsEarned } = response.data;
        setCorrectIndexes(correctIndexes); // Устанавливаем угаданные индексы для подсветки
        setModalData({
          correctCount,
          coinsEarned,
        });

        // Обновляем баланс пользователя
        axios.post('/api/update_balance', { user_id: user.user_id, coins: coinsEarned });

        // Сохраняем данные попытки в базу
        const now = new Date().toISOString().slice(0, 19).replace('T', ' '); // Для MySQL
        axios.post('/api/update_last_attempt_time', {
          user_id: user.user_id,
          last_attempt_time: now,
          attempted_cards: JSON.stringify(selectedImages),
          correct_indexes: correctIndexes,
        })
        .then(() => {
          setIsInteractionLocked(true); // Блокируем взаимодействие на 24 часа
        })
        .catch((err) => {
          console.error('Ошибка при обновлении времени попытки на сервере:', err);
        });
      })
      .catch((err) => {
        console.error('Ошибка при проверке комбинации:', err);
      });
  };

  return (
    <main className="main">
      <section className="screen">
        <div className="screen-bg">
          <img src={skyBg} alt="bg" className="screen-bg__img" />
        </div>
        <div className="screen-content scroll">
          <div className="wrapper">
            <div className="rating">
              <p className="rating-title">Комбо</p>
              <div className="combo">
                <div className="combo-row">
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      className={`combo-row__item ${correctIndexes.includes(index) ? 'correct' : ''}`}
                      onDrop={(e) => handleDropSlot(index, e)} // Обработка сброса в слот
                      onDragOver={(e) => e.preventDefault()} // Разрешаем сброс
                      onClick={() => !isInteractionLocked && handleSlotClickMobile(index)} // Для мобильных: добавляем изображение по клику
                    >
                      <img src={questionIcon} alt="icon" className="combo-row__item-icon" />
                      {image ? (
                        <>
                          <img src={image.src} alt="selected" className="combo-row__item-img" />
                          {!isInteractionLocked && (
                            <span className="combo-row__item-delete" onClick={() => handleRemove(index)}>
                              <img src={crossIcon} alt="cross" className="combo-row__item-cross" />
                            </span>
                          )}
                        </>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="combo-grid">
                  {images.map((image) => (
                    <div
                      key={image.id}
                      className={`combo-grid__item ${isImageUsed(image) ? 'disabled' : ''}`}
                      draggable={!isImageUsed(image) && !isInteractionLocked}
                      onDragStart={(e) => handleDragStart(e, image)}
                      onClick={() => !isInteractionLocked && handleImageSelectMobile(image)}

                    >
                      <img src={image.src} alt={`combo ${image.id}`} className="combo-grid__item-img" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Модальное окно для результата */}
      {modalData && (
        <Modal
          correctCount={modalData.correctCount}
          coinsEarned={modalData.coinsEarned}
          onClose={() => setModalData(null)}
        />
      )}

      <BottomNav user={user} onNavClick={() => {}} />
    </main>
  );
}

export default Combo;
