import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import Loader from './Loader';
import ErrorModal from './ErrorModal';
import VideoCodeModal from './modals/VideoCodeModal';
import SuccessModal from './modals/SuccessModal';
import DailyBonusModal from './modals/DailyBonusModal'; // Модальное окно для ежедневного бонуса

// Импорт изображений
import skyBg from '../assets/img/sky-bg.png';
import coinIcon from '../assets/img/icons/coin.svg';
import youtubeIcon from '../assets/img/tasks/youtube.svg';
import contactIcon from '../assets/img/tasks/contact.svg';
import giftIcon from '../assets/img/tasks/gift.svg';
import youtubeRedIcon from '../assets/img/tasks/youtube-red.svg';
import twitterIcon from '../assets/img/tasks/twitter.svg';
import instagramIcon from '../assets/img/tasks/instagram.svg';
import telegramIcon from '../assets/img/tasks/telegram.svg';

// Импорт CSS файлов
import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

function Tasks({ user, token, refreshUserData }) {
  const [tasks, setTasks] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [canClaimBonus, setCanClaimBonus] = useState(false); // Флаг для активности кнопки
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [videoCodeModalVisible, setVideoCodeModalVisible] = useState(false);
  const [dailyBonusModalVisible, setDailyBonusModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [taskStates, setTaskStates] = useState({});

  // Загружаем задания и бонусы при загрузке компонента
  useEffect(() => {
    fetchTasks();
    fetchBonuses(); // Запрашиваем бонусы при загрузке
    checkDailyBonusAvailability(); // Проверка на ежедневный бонус
  }, []);

  // Получение всех активных заданий
  const fetchTasks = async () => {
    try {
      const response = await axios.get('/api/tasks', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setTasks(response.data);
      setIsLoading(false);
    } catch (error) {
      setError('Ошибка при загрузке заданий.');
      setIsLoading(false);
    }
  };

  // Получение бонусов
  const fetchBonuses = async () => {
    try {
      const response = await axios.get('/api/bonuses', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setBonuses(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке бонусов:', error);
    }
  };

  // Проверка доступности ежедневного бонуса
  const checkDailyBonusAvailability = async () => {
    try {
      const response = await axios.get('/api/user_last_bonus', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setCanClaimBonus(response.data.canClaim); // Устанавливаем возможность получения бонуса
    } catch (error) {
      console.error('Ошибка при проверке возможности получения бонуса:', error);
    }
  };

  // Логика для внешних ссылок (YouTube, Instagram, Twitter, Telegram)
  const handleExternalLinkTask = (task) => {
    if (taskStates[task.id] === 'checking') {
      completeTask(task); // Выполняем задачу после проверки
    } else {
      window.open(task.link, '_blank'); // Открываем внешнюю ссылку
      if (task.task_type === 'telegram') {
        window.Telegram.WebApp.close(); // Закрываем веб-приложение для Telegram-заданий
      }
      setTaskStates((prevState) => ({
        ...prevState,
        [task.id]: 'checking',
      }));
    }
  };

  // Логика для видео-заданий (просмотр видео и проверка кода)
  const handleVideoTask = (task) => {
    if (taskStates[task.id] === 'checking') {
      setVideoCodeModalVisible(true); // Открываем модальное окно для ввода кода
      setSelectedTask(task); // Запоминаем текущее задание
    } else {
      window.open(task.link, '_blank'); // Открываем видео
      setTaskStates((prevState) => ({
        ...prevState,
        [task.id]: 'checking',
      }));
    }
  };

  // Отправка кода для проверки видео-задания
  const submitVideoCode = (code, setErrorMessage) => {
    axios
      .post(
        '/api/verify_video_code',
        { task_id: selectedTask.id, code, user_id: user.user_id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.data.success) {
          completeTask(selectedTask); // Отмечаем задание как выполненное
          setVideoCodeModalVisible(false); // Закрываем модальное окно
        } else {
          setErrorMessage('Неверный код.');
        }
      })
      .catch(() => {
        setErrorMessage('Ошибка при вводе кода.');
      });
  };

  // Логика для получения ежедневного бонуса
  const handleDailyBonusTask = (task) => {
    setDailyBonusModalVisible(true); // Показываем модальное окно с бонусом
    setSelectedTask(task); // Запоминаем текущее задание
  };

  // Подтверждение получения ежедневного бонуса
  const claimDailyBonus = async () => {
    try {
      const response = await axios.post(
        '/api/daily_bonus',
        {
          user_id: user.user_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        completeTask(selectedTask); // Отмечаем задание как выполненное
        setDailyBonusModalVisible(false); // Закрываем модальное окно
        checkDailyBonusAvailability(); // Обновляем доступность бонуса
      } else {
        alert(response.data.message); // Показываем сообщение об ошибке
      }
    } catch (error) {
      setError('Ошибка получения ежедневного бонуса.');
    }
  };

  // Завершение задачи
  const completeTask = async (task) => {
    try {
      const response = await axios.post(
        '/api/complete_task',
        {
          task_id: task.id,
          user_id: user.user_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        fetchTasks(); // Обновляем список заданий после завершения
        setSuccessMessage(`${task.name} успешно выполнено!`);
        setSuccessModalVisible(true); // Показываем модальное окно с успехом
        refreshUserData(); // Обновляем данные пользователя
      } else {
        alert(response.data.message); // Показываем сообщение об ошибке
      }
    } catch (error) {
      setError('Ошибка выполнения задания.');
    }
  };

  // Логика для подписки на платформы (Telegram, YouTube, Instagram, Twitter)
  const handleSubscriptionTask = (task) => {
    handleExternalLinkTask(task);
  };

  // Обработчик нажатия на кнопку "Выполнить" в зависимости от типа задания
  const handleTaskClick = (task) => {
    if (task.is_completed) return;

    switch (task.task_type) {
      case 'video':
        handleVideoTask(task);
        break;
      case 'subscription':
        handleSubscriptionTask(task);
        break;
      case 'daily':
        handleDailyBonusTask(task);
        break;
      case 'contact':
        completeTask(task);
        break;
      default:
        completeTask(task);
    }
  };

  // Обработка загрузки данных
  if (isLoading) {
    return <Loader />;
  }

  // Обработка ошибок
  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <main className="main">
      <section className="screen">
        <div className="screen-bg">
          <img src={skyBg} alt="bg" className="screen-bg__img" />
        </div>
        <div className="screen-content scroll">
          <div className="wrapper">
            <div className="tasks">
              <div className="tasks-header">
                <img src={coinIcon} alt="coin" className="tasks-header__icon" />
                <p className="tasks-header__title">Заработай больше монет,<br /> выполняя задания</p>
              </div>
              <div className="tasks-list">
                {tasks.map((task) => (
                  <div key={task.id} className={`tasks-list__item ${task.is_completed ? 'completed' : ''}`}>
                    <div className="tasks-list__item-place">
                      <img
                        src={
                          task.task_type === 'video'
                            ? youtubeIcon
                            : task.task_type === 'contact'
                            ? contactIcon
                            : task.task_type === 'daily'
                            ? giftIcon
                            : task.task_type === 'subscription' && task.platform === 'youtube'
                            ? youtubeRedIcon
                            : task.platform === 'twitter'
                            ? twitterIcon
                            : task.platform === 'instagram'
                            ? instagramIcon
                            : telegramIcon
                        }
                        alt="icon"
                        className="tasks-list__item-icon"
                      />
                    </div>
                    <div className="tasks-list__item-column">
                      <p className="tasks-list__item-name">{task.name}</p>
                      <p className="tasks-list__item-bonus">
                        <img src={coinIcon} alt="coin" className="tasks-list__item-coin" />
                        + {task.reward} монет
                      </p>
                    </div>
                    {task.is_completed ? (
                      <span>Выполнено</span>
                    ) : (
                      <button onClick={() => handleTaskClick(task)}>
                        {taskStates[task.id] === 'checking' ? 'Проверить' : 'Выполнить'}
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <BottomNav user={user} onNavClick={() => {}} />

      {/* Модальные окна */}
      {videoCodeModalVisible && (
        <VideoCodeModal
          task={selectedTask}
          onClose={() => setVideoCodeModalVisible(false)}
          onConfirm={(code, setErrorMessage) => submitVideoCode(code, setErrorMessage)}
        />
      )}

      {dailyBonusModalVisible && bonuses.length > 0 && (
        <DailyBonusModal
          user={user}
          bonuses={bonuses}
          canClaimBonus={canClaimBonus}
          onClose={() => setDailyBonusModalVisible(false)}
          onConfirm={claimDailyBonus}
        />
      )}

      <SuccessModal
        isVisible={successModalVisible}
        message={successMessage}
        onClose={() => setSuccessModalVisible(false)}
      />
    </main>
  );
}

export default Tasks;
