import React from 'react';

const ResultModal = ({ correctCount, coinsEarned, onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Результат проверки</h2>
        <p>Вы угадали {correctCount} из 4 карточек.</p>
        <p>Вам начислено {coinsEarned.toLocaleString()} монет.</p>
        <button onClick={onClose}>Закрыть</button>
      </div>
    </div>
  );
};

export default ResultModal;
