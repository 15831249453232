import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

import Modal from './modals/AlertButtomCombo';


// Импорт изображений
import menu1 from '../assets/img/icons/menu-1.png';
import menu2 from '../assets/img/icons/menu-2.png';
import menu3 from '../assets/img/icons/menu-3.png';
import menu4 from '../assets/img/icons/menu-4.png';
import menu5 from '../assets/img/icons/menu-5.png';

const BottomNav = ({ active, user }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNavClick = (route, requiresLevel = 0) => {
    if (route === '/combo' && user.level < requiresLevel) {
      setIsModalOpen(true);
    } else {
      navigate(route);
    }
  };

  return (
    <footer className="footer">
      <div className="wrapper">
        <nav className="menu">
          <a
            href="#!"
            className={`menu-link ${active === 'tasks' ? 'active' : ''}`}
            onClick={() => handleNavClick('/tasks')}
          >
            <img src={menu1} alt="icon" className="menu-link__icon" />
            Задания
          </a>
          <a
            href="#!"
            className={`menu-link ${active === 'boosts' ? 'active' : ''}`}
            onClick={() => handleNavClick('/boosts')}
          >
            <img src={menu2} alt="icon" className="menu-link__icon" />
            Бусты
          </a>
          <a
            href="#!"
            className={`menu-link ${active === 'home' ? 'active' : ''}`}
            onClick={() => handleNavClick('/')}
          >
            <img src={menu3} alt="icon" className="menu-link__icon" />
            Главная
          </a>
          <a
            href="#!"
            className={`menu-link ${active === 'combo' ? 'active' : ''}`}
            onClick={() => handleNavClick('/combo', 5)}
          >
            <img src={menu4} alt="icon" className="menu-link__icon" />
            Комбо
          </a>
          <a
            href="#!"
            className={`menu-link ${active === 'referrals' ? 'active' : ''}`}
            onClick={() => handleNavClick('/referrals')}
          >
            <img src={menu5} alt="icon" className="menu-link__icon" />
            Рефералы
          </a>
        </nav>
      </div>

      {/* Модальное окно для предупреждения */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          message="Комбо доступно только для пользователей с уровнем 5 и выше."
        />
      )}
    </footer>
  );
};

export default BottomNav;
