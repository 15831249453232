import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import BottomNav from './BottomNav';
import ErrorModal from './ErrorModal';
import skyBg from '../assets/img/main-bg.png';
import boostIcon from '../assets/img/icons/boost.png';
import coinIcon from '../assets/img/icons/coin.svg';
import zoom from '../assets/img/monkeys/zoom.png';
import animationData from '../assets/animations/index.json';

import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

const Game = ({ user, token, refreshUserData }) => {
  const [currentUser, setCurrentUser] = useState(user);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('home');
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [isLangDropdownVisible, setIsLangDropdownVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // Состояние для управления анимацией
  const lottieRef = useRef(null); // Реф для управления анимацией Lottie

  const toggleLangDropdown = () => {
    setIsLangDropdownVisible(!isLangDropdownVisible);
  };

  useEffect(() => {
    if (currentUser && currentUser.user_id && token) {
      fetchUserData(token, currentUser.user_id)
        .then((userData) => {
          setCurrentUser(userData);
          setIsLoading(false);
          updateProgressBar(userData.energy, userData.energy_limit);
        })
        .catch((error) => {
          setError(`Failed to fetch user data: ${error.message}`);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [currentUser.user_id, token]);

  const fetchUserData = async (token, userId) => {
    try {
      const response = await axios.get(`/api/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleTouchStart = async (e) => {
    e.preventDefault();

    if (currentUser.energy >= currentUser.click_value) {
      // Обновляем баланс и энергию пользователя
      const updatedUser = {
        ...currentUser,
        coins: currentUser.coins + currentUser.click_value,
        energy: currentUser.energy - currentUser.click_value,
      };

      try {
        const res = await axios.post('/api/user/update', updatedUser, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.data.success) {
          setCurrentUser(updatedUser);
          setIsAnimating(true); // Включаем анимацию только при успешном обновлении
        }
      } catch (err) {
        console.error('Ошибка при обновлении пользователя:', err);
        setError('Failed to update user.');
      }
    } else {
      setError('Not enough energy to perform action.');
    }
  };

  const handleTouchEnd = () => {
    setIsAnimating(false); // Останавливаем анимацию, когда пользователь перестает тапать
  };

  useEffect(() => {
    if (lottieRef.current) {
      if (isAnimating) {
        lottieRef.current.play(); // Воспроизводим анимацию
      } else {
        lottieRef.current.stop(); // Останавливаем анимацию
      }
    }
  }, [isAnimating]);

  const handleNavClick = (page) => {
    setActiveTab(page);
  };

  const updateProgressBar = (current, limit) => {
    const percentage = (current / limit) * 100;
    setProgressBarWidth(percentage);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <main className="main">
      <section className="screen">
        <div className="screen-bg">
          <span className="screen-bg__fade top"></span>
          <img src={skyBg} alt="bg" className="screen-bg__img" />
          <span className="screen-bg__fade bottom"></span>
        </div>
        <div className="screen-content">
          <div className="wrapper">
            <div className="home">
              <div className="home-top">
                <nav className="home-top__menu">
                  <Link to="/leaderboard" className="home-top__menu-link">Рейтинг</Link>
                  <a href="#!" className="home-top__menu-link notify">р2р</a>
                  <a href="#!" className="home-top__menu-link disabled">НФТ (скоро)</a>
                  <div className="home-top__lang">
                    <button className="home-top__menu-link" onClick={toggleLangDropdown}>Язык</button>
                    {isLangDropdownVisible && (
                      <div className="home-top__lang-content">
                        <a href="#" className="active">Русский</a>
                        <a href="#">English</a>
                      </div>
                    )}
                  </div>
                </nav>
                <p className="home-top__nickname">{currentUser.name_user_user_id}</p>
                <p className="home-top__balance">
                  <img src={coinIcon} alt="coin" className="home-top__balance-icon" />
                  <span>{currentUser.coins}</span>
                </p>
              </div>
              <div className="home-monkey">
                <div
                  className="home-monkey__block"
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                >
                  <img src={zoom} alt="zoom" className="home-monkey__zoom" />
                  <Lottie
                    animationData={animationData}
                    lottieRef={lottieRef}
                    loop={false}
                    autoplay={false}
                    style={{ width: 200, height: 200, frameRate: 30 }} // Уменьшенный размер анимации
                    className="home-monkey__lottie"
                  />
                </div>
              </div>
              <div className="home-bottom">
                <div className="home-bottom__boost">
                  <p className="home-bottom__boost-number">
                    <img src={boostIcon} alt="boost" className="home-bottom__boost-icon" />
                    <span>
                      <span className="big">{currentUser.energy}</span>/{currentUser.energy_limit}
                    </span>
                  </p>
                  <div className="home-bottom__boost-bar">
                    <span
                      className="home-bottom__boost-progress"
                      style={{ width: `${progressBarWidth}%` }}
                    ></span>
                  </div>
                </div>
                <div className="home-bottom__lvl">
                  <div className="home-bottom__lvl-row">
                    <p className="home-bottom__lvl-number">01 Уровень</p>
                    <p className="home-bottom__lvl-upgrade">Повысить уровень</p>
                  </div>
                  <div className="home-bottom__lvl-dots">
                    {[...Array(40)].map((_, index) => (
                      <span key={index} className={`home-bottom__lvl-dot ${index > 30 ? 'disabled' : ''}`}></span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomNav user={currentUser} onNavClick={handleNavClick} />
    </main>
  );
};

export default Game;
