import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Game from './components/Game';
import Referrals from './components/Referrals';
import Tasks from './components/Tasks';
import Boosts from './components/Boosts';
import Leaderboard from './components/Leaderboard';
import Combo from './components/Combo';
import ErrorBoundary from './ErrorBoundary';

function AppRouter({ user, token, refreshUserData  }) {
  return (
    <Router>
      <div className="App">
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Game user={user} token={token} refreshUserData={refreshUserData} />} />
            <Route path="/referrals" element={<Referrals  user={user}  token={token} />} />
            <Route path="/boosts" element={<Boosts user={user} token={token} refreshUserData={refreshUserData} />} />
            <Route path="/leaderboard" element={<Leaderboard user={user}  token={token} />} />
            <Route path="/tasks" element={<Tasks  user={user}   token={token}  refreshUserData={refreshUserData} />} />
            <Route path="/combo" element={<Combo  user={user}   token={token}  refreshUserData={refreshUserData} />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </Router>
  );
}

export default AppRouter;
