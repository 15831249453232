import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import ErrorModal from './ErrorModal';
import Loader from './Loader';

// Импорт CSS файлов
import '../assets/css/vars.css';
import '../assets/css/style.css';
import '../assets/css/media.css';
import '../assets/css/custom-select.css';

// Импорт изображений
import skyBg from '../assets/img/sky-bg.png';
import contactIcon from '../assets/img/icons/contact.svg';
import giftIcon from '../assets/img/tasks/gift.svg';
import coinIcon from '../assets/img/icons/coin.svg';
import reloadIcon from '../assets/img/icons/reload-light.svg';
import starIcon from '../assets/img/icons/star.svg';

function Referrals({ user, token }) {

  const [referrals, setReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user && user.user_id && token) {
      fetchReferrals(user.user_id, token);
    } else {
      setIsLoading(false); // Завершаем загрузку, если user или token не определены
    }
  }, [user, token]);

  const fetchReferrals = (userId, token) => {
    console.log("Запрашиваем рефералы для пользователя: ", userId);
    axios.get(`/api/referrals/${userId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(res => {
        console.log("Рефералы получены: ", res.data);
        setReferrals(res.data);
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Ошибка при запросе к /api/referrals:', err);
        setError('Ошибка при загрузке данных рефералов.');
        setIsLoading(false);
      });
  };

  const handleCopyLink = () => {
    if (user && user.user_id) {
      const referralLink = `https://t.me/TestClicksBot?start=${user.user_id}`;
      console.log("Копирование ссылки: ", referralLink);
      navigator.clipboard.writeText(referralLink).then(() => {
        alert('Ссылка скопирована!');
      }).catch(err => {
        console.error('Ошибка при копировании ссылки:', err);
      });
    }
  };

  const handleInviteFriend = () => {
    if (user && user.user_id) {
      const referralLink = `https://t.me/TestClicksBot?start=${user.user_id}`;
      console.log("Пригласить друга со ссылкой: ", referralLink);
      navigator.clipboard.writeText(referralLink).then(() => {
        alert('Ссылка скопирована, выберите диалог и вставьте её!');
        window.Telegram.WebApp.Minimize(); // Сворачиваем приложение, не закрывая его
      }).catch(err => {
        console.error('Ошибка при копировании ссылки:', err);
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <main className="main">
      <section className="screen">
        <div className="screen-bg">
          <img src={skyBg} alt="bg" className="screen-bg__img" />
        </div>
        <div className="screen-content scroll">
          <div className="wrapper">
            <div className="tasks">
              <div className="tasks-header">
                <img src={contactIcon} alt="coin" className="tasks-header__icon" />
                <p className="tasks-header__title">
                  Пригласите друзей и<br /> получите бонусы!
                </p>
              </div>
              <div className="tasks-list">
                <div className="tasks-list__item">
                  <div className="tasks-list__item-place">
                    <img src={giftIcon} alt="icon" className="tasks-list__item-icon" />
                  </div>
                  <div className="tasks-list__item-column">
                    <p className="tasks-list__item-name">Пригласить друга</p>
                    <p className="tasks-list__item-bonus">
                      <img src={coinIcon} alt="coin" className="tasks-list__item-coin" />
                      + 10 000 монет для вас и реферала
                    </p>
                  </div>
                </div>
                <div className="tasks-list__item">
                  <div className="tasks-list__item-place">
                    <img src={giftIcon} alt="icon" className="tasks-list__item-icon" />
                  </div>
                  <div className="tasks-list__item-column">
                    <p className="tasks-list__item-name">Пригласить друга с Telegram Premium</p>
                    <p className="tasks-list__item-bonus">
                      <img src={coinIcon} alt="coin" className="tasks-list__item-coin" />
                      + 10 000 монет для вас и реферала
                    </p>
                  </div>
                </div>
              </div>

              <div className="friends">
                <div className="friends-top">
                  <p className="friends-top__title">Список ваших друзей</p>
                  <img
                    src={reloadIcon}
                    alt="reload"
                    className="friends-top__reload"
                    onClick={() => fetchReferrals(user.user_id, token)}
                  />
                </div>
                <div className="friends-list">
                  {referrals.length > 0 ? (
                    referrals.map((referral) => (
                      <div key={referral.user_id} className="friends-list__item">
                        {referral.premium === 1 ? (
                          <img src={starIcon} alt="star" className="friends-list__item-star" />
                        ) : (
                          <span></span>
                        )}
                        <p className="friends-list__item-text">
                          {referral.username || 'Аноним'} {referral.premium === 1 && '★'}
                        </p>
                        <p className="friends-list__item-text">
                          <img src={coinIcon} alt="coin" className="friends-list__item-coin" />
                          {referral.coins}
                        </p>
                        <p className="friends-list__item-text">{referral.level} ур.</p>
                      </div>
                    ))
                  ) : (
                    <p className="friends-list__empty">У вас пока нет рефералов.</p>
                  )}
                </div>

                <div className="friends-bottom">
                  <button className="button" onClick={handleInviteFriend}>
                    Пригласить друга
                  </button>
                  <button className="button black" onClick={handleCopyLink}>
                    Копировать ссылку
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomNav user={user} onNavClick={() => {}} />
    </main>
  );
}

export default Referrals;
