import React, { useState } from 'react';  // Добавляем useState здесь

function VideoCodeModal({ task, onClose, onConfirm }) {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState('');  // Тип сообщения (успех или ошибка)

  const handleConfirm = () => {
    if (code.trim() !== '') {
      onConfirm(code, setErrorMessage, setMessageType);  // Передаем код и функции для установки ошибок
    } else {
      setErrorMessage('Введите код.');
      setMessageType('error');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        {errorMessage && <div className={`message ${messageType}`}>{errorMessage}</div>}
        <h2>Введите код из видео</h2>
        <p>Для задания: {task.name}</p>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Введите код"
        />
        <div className="modal-buttons">
          <button onClick={handleConfirm}>Подтвердить</button>
          <button onClick={onClose}>Отмена</button>
        </div>
      </div>
    </div>
  );
}

export default VideoCodeModal;
