import React from 'react';

function DailyBonusModal({ user, onClose, onConfirm, bonuses, canClaimBonus }) {
  const currentDay = (user.dailyBonusDay || 1) - 1;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Ежедневный бонус</h2>
        <p>Заберите свой бонус!</p>
        <div className="bonus-grid">
          {bonuses.map((bonus, index) => (
            <div key={bonus.day} className={`bonus-item ${index <= currentDay ? 'claimed' : ''}`}>
              <span>День {bonus.day}</span>
              <span>{bonus.bonus} монет</span>
            </div>
          ))}
        </div>
        <div className="modal-buttons">
          <button onClick={onConfirm} disabled={!canClaimBonus}>
            Забрать
          </button>
          <button onClick={onClose}>Отмена</button>
        </div>
        {!canClaimBonus && (
          <p>Вы уже получили бонус. Попробуйте снова через 24 часа.</p>
        )}
      </div>
    </div>
  );
}

export default DailyBonusModal;
